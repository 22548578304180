var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", [
        _vm._v("Your order is paid for, now we just need a few more details."),
      ]),
      _c("p", [
        _vm._v(
          "To help establish your company registration, we need to gather a few more pieces of information about the business registration you need completed."
        ),
      ]),
      _c("p", [_vm._v("Thanks for using our services!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }